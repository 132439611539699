@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}

.box {
  /* position: relative; */
  border-radius: 3px;
  /* background: #ffffff; */
  border-top: 3px solid #d2d6de;
  /* margin-bottom: 20px; */
  width: 100%;
  /* box-shadow: 0 1px 1px rgb(0 0 0 / 10%); */
}

.box.box-info {
  border-top-color: #00c0ef;
}

.box.box-success {
  border-top-color: #00a65a;
}

.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
  font-weight: 700;
}

.box-body {
  /* border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; */
  padding: 10px;
}

.input-feedback-roles {
  color: red;
  margin-top: 0rem;
  margin-top: -1rem;
  padding-left: 17%;
}

input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error {
  border-color: red;
}
