@font-face {
  font-family: 'CSChatThai';
  src: local('CSChatThai'), url(fonts/CSChatThaiUI.ttf) format('truetype');
}

* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}


body {
  margin: 0;
  font-family: 'CSChatThai'; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  /* background: #ecf0f3; */
  background: #FFF;
  min-height: 100%;
  overflow-y: auto;
}


code {
  font-family: 'CSChatThai';
}

.modal-open {
  padding-right: 0px !important;
}