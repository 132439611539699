.bgNotFound {
    background: url("../../../../../public/img/404Notfound.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    /* height: 100%; */
    height: calc(100vh - 100px);
    /* min-height: 100vh; */
    /* border:5px solid red; */
    box-sizing: border-box;
    overflow: hidden;
  }
  